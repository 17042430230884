import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { EConfig } from '../constants/EConfig'
import { getRedeemConfig } from "../utils/redeem";

const SubmissionsChecker = () => {
  const [submissionsLeft, setSubmissionsLeft] = useState(null)
  const [copied, setCopied] = useState(false)

  const [redeemNumber, setRedeemNumber] = useState(0)
  const [showLink, setShowLink] = useState(false) // State for showing link block

  useEffect(() => {
    const fetchRedeemConfig = async () => {
      try {
        if (redeemNumber != 0) {
          return;
        }
        const response = await getRedeemConfig();
        if (response?.data?.redeem_number) {
          setRedeemNumber(response.data.redeem_number)
        }
      } catch (error) {
      }
    }
    fetchRedeemConfig().catch(console.log)
  }, [])

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await axios.post(`${EConfig.API_BASE_URL}wp-json/api/submissions-to-next-redeem`)
        console.log(response);

        if (response.data.success === 1) {
          setSubmissionsLeft(response.data.data.submissions_to_next_redeem)
        }
      } catch (error) {
        console.error('Error fetching submissions data', error)
      }
    }

    fetchSubmissions().catch(console.log)

    const interval = setInterval(fetchSubmissions, 30000)

    return () => clearInterval(interval)
  }, [])

  const handleCopyLink = () => {
    const link = 'https://t.me/herewalletbot/app?startapp=web-tg-learnnear-club'
    navigator.clipboard.writeText(link).then(() => {
      setCopied(true)
      setShowLink(false)
      setTimeout(() => setCopied(false), 2000)
    }).catch(err => {
      console.error('Failed to copy the link: ', err);
      setShowLink(true)
    });
  }

  if (submissionsLeft === null || submissionsLeft === 0) {
    return null
  }

  return (
      <div className="submissions-checker">
        <div className="submissions-item">
          <div className="submissions-value" style={{ textAlign: 'center' }}>
            {new Intl.NumberFormat().format(submissionsLeft)} more (L)Earners to start <br /><b>$NEAR Redeem {redeemNumber ? redeemNumber : ''}🤑</b>
          </div>
          <button className="copy-link-button" onClick={handleCopyLink}>
            Invite friends NOW! <FontAwesomeIcon icon={faCopy} />
          </button>

          {copied && <span style={{ color: 'green', marginLeft: '5px' }}>Copied!</span>}

          {/* Show the link block if clipboard access is blocked */}
          {showLink && (
              <div style={{ marginTop: '10px', background: '#f0f0f0', padding: '10px', borderRadius: '5px' }}>
                <strong>Unable to copy the link. Please copy it manually:</strong>
                <div>{'https://t.me/herewalletbot/app?startapp=web-tg-learnnear-club'}</div>
              </div>
          )}
        </div>
      </div>
  )
}

export default SubmissionsChecker
