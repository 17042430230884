import React from 'react';
import { Navbar } from 'react-bootstrap';

const Footer = () => {
  return (
    <Navbar bg="light" variant="dark" expand="lg" fixed="bottom" className="footer">
      <Navbar.Brand href="/">
        <img
          src="/logo-main.svg"
          className="d-inline-block align-top mr-2"
          alt="Logo"
          style={{ maxWidth: '200px' }}
        />
        <span className="d-none d-lg-inline">
          Learn Near Club
        </span>
        <br/>
        <a href={'https://learnnear.club/hot/'} style={{color:"#00c08b"}} target={'_blank'}>Support</a>
        <span style={{ color: 'black', fontSize: '8px' }}> v0.1.35</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
        <Navbar.Text className="text-dark">
          © {new Date().getFullYear()} Learn Near Club
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Footer;
